import {
    SET_LANG
} from '../types';

const initialState = {
    countrycode:'DE',
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type){
        case SET_LANG:
            return {
                ...state,
                countrycode:payload,
            };
        default:
            return state;
    }
}