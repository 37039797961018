import {
    FETCH_CHAT, 
    SAVE_CHAT,
    FETCH_NEWMESSAGE,
} from '../types';

const initialState = {
    chat_list: [],
    new_chat: {},
    new_messages: {},
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type){
        case FETCH_CHAT:
            return {
                ...state,
                chat_list:payload,
            };
        case SAVE_CHAT:
            return { ...state, new_chat:payload };
        case FETCH_NEWMESSAGE:
            return { ...state, new_messages:payload };
        default:
            return state;
    }
}