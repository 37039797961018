import { combineReducers } from "redux";

import authReducer from "./authReducer";
import notiferReducer from "./notifReducer";
import uiReducer from "./uiReducer";
import profileReducer from "./profileReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import categoryReducer from "./categoryReducer";
import adminReducer from "./adminReducer";
import chatReducer from "./chatReducer";
import langReducer from "./langReducer";

export default combineReducers({
  admin: adminReducer,
  auth: authReducer,
  notif: notiferReducer,
  ui: uiReducer,
  profile: profileReducer,
  products: productReducer,
  cart: cartReducer,
  chat: chatReducer,
  category: categoryReducer,
  lang:langReducer,
});
