import React, { useEffect } from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import logo from "../../logo.png"

function Footer(props) {
    const countrycode = useSelector(state => state.lang.countrycode);

    useEffect(() => { }, [countrycode])
    return (
        <div className="w-full" style={{ marginTop: "2rem" }}>
            <Grid container spacing={2} className="w-full relative bottom-0 ">
                <Grid item xs={12} md={12} lg={12} className="bg-gray-200 pt-8 mt-4 flex text-center">
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingTop: 10, paddingRight: 10 }}>
                            <img src={logo} alt="logo png" width={250}
                                className="pl-2" style={{ display: "initial" }} />
                            <p className="pt-3 px-10 font-bold text-gray-700 ">{countrycode === "EN" ? "Contact" : "Kontakt"}</p>
                            <p className="pt-1 px-10 text-gray-700 text-sm">office@wenido.com</p>
                            <div className="p-5 contents pb-3" >
                                <a href="http://facebook.com/"><FacebookIcon className="text-blue-800 mx-4" /></a>
                                <a href="http://twitter.com/"><TwitterIcon className="text-blue-500 mx-4" /></a>
                                <a href="http://instegram.com/"><InstagramIcon className="text-orange-700 mx-4" /></a>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 5 }}>
                            <p className="font-bold  text-gray-700">{countrycode === "EN" ? "About Wenido" : "Über Wenido"}</p>
                            <a href="/"><p className="pt-2 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "How it works" : "Wie es funktioniert"}</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "Contact" : "Kontakt"}</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "About us" : "Über uns"}</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">Blog</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "Press" : "Presse"}</p></a>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingTop: 10, paddingLeft: 25, paddingRight: 5 }}>
                            <p className="font-bold  text-gray-700">{countrycode === "EN" ? "Additional" : "Weiteres"}</p>
                            <a href="/"><p className="pt-2 text-gray-700 hover:text-blue-500 text-sm">Jobs</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "Exchange clubs" : "TaENchvereine"}</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "GDPR" : "DSGVO"}</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "Conditions" : "AGB"}</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "Imprint" : "Impressum"}</p></a>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 5 }}>
                            <p className="font-bold  text-gray-700">Account</p>
                            <a href="/"><p className="pt-2 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "Register" : "Registrieren"}</p></a>
                            <a href="/"><p className="pt-1 text-gray-700 hover:text-blue-500 text-sm">{countrycode === "EN" ? "Log In" : "Einloggen"}</p></a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="h-10 " style={{ padding: 0 }}>
                    <p className="bg-gray-400 h-full pl-6 flex text-center items-center">
                        &copy; Wenido - developed by&nbsp;<a target="_blank" href="https://blueduck.at" className="text-blue-700" style ={{textWeight:"bold"}}>BlueDuck</a>
                    </p>
                    
                </Grid>
            </Grid>
        </div>
    );
}

export default Footer;