export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_PENDING = "AUTH_PENDING";
export const AUTH_FAIL = "AUTH_FAIL";

export const ADD_NOTIF = "ADD_NOTIF";
export const REMOVE_NOTIF = "REMOVE_NOTIF";

export const FETCH_CHAT = "FETCH_CHAT";
export const SAVE_CHAT = "SAVE_CHAT";
export const FETCH_NEWMESSAGE = "FETCH_NEWMESSAGE";

export const START_LOADING_UI = "START_LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const START_LOADING_BUTTON = "START_LOADING_BUTTON";
export const STOP_LOADING_BUTTON = "STOP_LOADING_BUTTON";

export const FETCH_ADDRESSES = "FETCH_ADDRESSES";
export const CREATE_ADDRESS = "CREATE_ADDRESSES";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";

export const FETCH_FAVORITE_PRODUCTS = "FETCH_FAVORITE_PRODUCTS";
export const UPDATE_FAVORITE_PRODUCTS = "UPDATE_FAVORITE_PRODUCTS";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT = "FETCH_PRODUCT";

export const FETCH_CART = "FETCH_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDER = "FETCH_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES"
// -----new-profile----
export const FETCH_MY_PRODUCTS = "FETCH_MY_PRODUCTS"

export const FETCH_USER_PRODUCTS = "FETCH_USER_PRODUCTS"

export const FETCH_USER_DATA = "FETCH_USER_DATA"

export const FETCH_USER_ADDRESS = "FETCH_USER_ADDRESS"


//alek 2020 - 09 - 22 
export const FETCH_USERS = "FETCH_USERS";
export const DELETE_USER = "DELETE_USERS";

export const FETCH_OLDPRODUCTS = "FETCH_OLDPRODUCT";
export const RECREATE_OLDPRODUCT = "RECREATE_OLDPRODUCT";
export const DELETE_OLDPRODUCT = "DELETE_OLDPRODUCT";

export const SET_LANG = "SET_LANG";