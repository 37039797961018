import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AuthRoute from "./components/routes/AuthRoute";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import Loading from "./components/loading/Loading";
import Footer from "./components/layouts/Footer";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {  yellow } from "@material-ui/core/colors";
import { useSelector } from "react-redux";
const Header = React.lazy(() => import("./components/layouts/Header"));


const Login = React.lazy(() =>
  import(/* webpackChunkName: "login" */ "./pages/authPages/login")
);

const Register = React.lazy(() =>
  import(/* webpackChunkName: "register" */ "./pages/authPages/register")
);

const Logout = React.lazy(() =>
  import(/* webpackChunkName: "logout" */ "./pages/authPages/logout")
);

const ChangePassword = React.lazy(() =>
  import(
    /* webpackChunkName: "change-password" */ "./pages/authPages/changePassword"
  )
);

/* const RequestResetEmail = React.lazy(() =>
  import(
     "./pages/authPages/requestResetEmail"
  )
); */

const ResetPassword = React.lazy(() =>
  import(
    /* webpackChunkName: "reset-password" */ "./pages/authPages/resetPassword"
  )
);

const ResetPasswordConfirm = React.lazy(() =>
  import(
    /* webpackChunkName: "reset-password-confirm" */ "./pages/authPages/resetPasswordConfirm"
  )
);

const Profile = React.lazy(() =>
  import(/* webpackChunkName: "profile" */ "./pages/profilePages/profile")
);

const ProfileProducts = React.lazy(() =>
  import(
    /* webpackChunkName: "personal-info" */ "./pages/profilePages/myProducts"
  )
);

const UserProducts = React.lazy(() =>
  import(
    /* webpackChunkName: "personal-info" */ "./pages/profilePages/userProducts"
  )
);

const AddProfileProduct = React.lazy(() =>
  import(
    /* webpackChunkName: "personal-info-edit" */ "./pages/profilePages/myProducts/AddProduct"
  )
);

const PersonalInfoEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "personal-info-edit" */ "./pages/profilePages/personalInfoEdit"
  )
);

const Addresses = React.lazy(() =>
  import(/* webpackChunkName: "addresses" */ "./pages/profilePages/addresses")
);

const FavoriteProducts = React.lazy(() =>
  import(
    /* webpackChunkName: "favorite-products" */ "./pages/profilePages/favoriteProducts"
  )
);

const Orders = React.lazy(() =>
  import(/* webpackChunkName: "orders-history" */ "./pages/profilePages/orders")
);

const OrdersDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "orders-detail" */ "./pages/profilePages/ordersDetail"
  )
);

const Products = React.lazy(() =>
  import(/* webpackChunkName: "products" */ "./pages/productPages/products")
);
const ProductsFilter = React.lazy(() =>
  import(
    /* webpackChunkName: "products" */ "./pages/productPages/ProductsFilter"
  )
);

const ProductsDetail = React.lazy(() =>
  import(
    /* webpackChunkName: "products-detail" */ "./pages/productPages/productsDetail"
  )
);

const ProductsDetailEdit = React.lazy(() =>
  import(
    /* webpackChunkName: "products-detail" */ "./pages/productPages/productsDetailEdit"
  )
);

const Cart = React.lazy(() =>
  import(/* webpackChunkName: "cart" */ "./pages/checkoutPages/cart")
);

const Order = React.lazy(() =>
  import(/* webpackChunkName: "order" */ "./pages/checkoutPages/order")
);

// Alek 2020-09-21 AM
const Admin = React.lazy(() =>
  import(/* webpackChunkName: "admin" */ "./pages/admin/admin")
);
const Chats = React.lazy(() =>
  import(/* webpackChunkName: "chats" */ "./pages/chats/chats")
);

const Index = () => <Redirect to="/products" />;
const Index1 = () => <Redirect to="/admin" />;

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: yellow[600],
    },
    secondary: {
      main: yellow[600],
    },
  },
});

const Routes = () => {
  // const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const isAdmin = useSelector((state) => state.auth.is_admin);
  return (
    <React.Suspense fallback={<Loading />}>
      <Header />
      <Loading inFetching />
      <div className="px-6 min-h-screen">
        <ThemeProvider theme={outerTheme}>
          <Switch>
            {/* {isAdmin === false ? <Route exact path="/" component={Index1}/>:<Route exact path="/" component={Index}/>} */}
            {isAdmin === true && <Route exact path="/" component={Index1} />}
            {isAdmin === false && <Route exact path="/" component={Index} />}
            <ProtectedRoute exact path="/admin" component={Admin} />
            <ProtectedRoute exact path="/chats" component={Chats} />
            <ProtectedRoute exact path="/logout" component={Logout} />
            <AuthRoute exact path="/login" component={Login} />
            <AuthRoute exact path="/register" component={Register} />
            <AuthRoute exact path="/reset-password" component={ResetPassword} />
            <AuthRoute
              exact
              path="/reset-password/:token"
              component={ResetPasswordConfirm}
            />
            <ProtectedRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />
            {/* <ProtectedRoute
            exact
            path="/request-reset-email"
            component={ChangePassword}
            /> */}
            <ProtectedRoute exact path="/profile" component={Profile} />

            <ProtectedRoute
              exact
              path="/profile/products"
              component={ProfileProducts}
            />

            <ProtectedRoute
              exact
              path="/profile/products/add"
              component={AddProfileProduct}
            />

            <ProtectedRoute
              exact
              path="/profile/personal-info/edit"
              component={PersonalInfoEdit}
            />

            <ProtectedRoute
              exact
              path="/profile/addresses"
              component={Addresses}
            />
            <ProtectedRoute
              exact
              path="/profile/favorite-products"
              component={FavoriteProducts}
            />
            <ProtectedRoute exact path="/profile/orders" component={Orders} />
            <ProtectedRoute
              exact
              path="/profile/orders/:id"
              component={OrdersDetail}
            />

            <Route exact path="/products" component={Products} />
            <Route exact path="/user/:slug" component={UserProducts} />

            <Route exact path="/products/filter" component={ProductsFilter} />
            <Route exact path="/products/:slug" component={ProductsDetail} />
            <ProtectedRoute exact path="/products/:slug/edit" component={ProductsDetailEdit} />
            <ProtectedRoute exact path="/cart" component={Cart} />
            <ProtectedRoute exact path="/order" component={Order} />
          </Switch>
        </ThemeProvider>
      </div>
      <Footer />

      {/*{matches && <MobileNavigation />}*/}
    </React.Suspense>
  );
};

export default Routes;
