import {
    FETCH_USERS, 
    DELETE_USER, 
    
    FETCH_OLDPRODUCTS, 
} from '../types';

const initialState = {
    users: [],
    user: {},
    oldproducts: [],
    oldproduct:{},
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type){
        case FETCH_USERS:
            return {
                ...state,
                users:payload,
            };
        case DELETE_USER:
            return { ...state, user:payload };
        case FETCH_OLDPRODUCTS:
            return { ...state, oldproducts:payload };
        default:
            return state;
    }
}